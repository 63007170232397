/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getCarrierProducts = async ({ queryKey }: any) => {
  const reqData = queryKey[1]
  const queryParams = reqData?.partnerCode
    ? `?PartnerCode=${reqData.partnerCode}`
    : ''
  const res = await get(`/CarrierProduct${queryParams}`)
  return res.data
}

export const useGetCarrierProducts = (
  reqData: { PartnerCode?: string },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['carrierProducts', reqData], getCarrierProducts, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useGetCarrierProducts
