import { FC, useState } from 'react'
import CN from 'classnames'
import { useCreateCourier } from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { Input } from 'components/atoms/Input'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'

export interface AddCourierProps {
  className?: string | undefined
  [x: string]: any
}

export const AddCourier: FC<AddCourierProps> = ({
  className,
  isActive,
  onCloseClick,
  notify,
  getCouriersList,
}: AddCourierProps) => {
  const AddCourierClasses = CN(
    'AddWarehouses flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )
  const [addCourierDetails, setAddCourierDetails] = useState<any>({
    carrier: '',
    productName: '',
    productCode: '',
    carrierCode: '',
    carrierName: '',
    partnerCode: '',
    isActive: false,
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    carrier: false,
    productName: false,
    productCode: false,
    carrierCode: false,
    carrierName: false,
    partnerCode: false,
    isActive: false,
  })

  /** API Call for Login function using react-query */
  const { mutate: createCourierMutate, isLoading: createCourierIsLoading } =
    useCreateCourier()

  /** Process the Login submission */
  async function addCourier() {
    createCourierMutate(addCourierDetails, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData.message, 'success')
        onCloseClick()
        getCouriersList()
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error', 'error')
      },
    })
  }

  const validateInputs = () => {
    const { carrier, productName, productCode } = addCourierDetails

    setIsErrorField({
      ...isErrorField,
      carrier: carrier ? false : true,
      productName: productName ? false : true,
      productCode: productCode ? false : true,
    })

    const isAllValidFields = carrier && productName && productCode
    return isAllValidFields
  }

  const handleAddClick = () => {
    const valid = validateInputs()

    if (valid) {
      addCourier()
    }
  }

  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={400}
      title='Add New Courier '>
      <div className={AddCourierClasses}>
        <Input
          labelValue='Courier'
          isRequired={true}
          type='text'
          value={addCourierDetails?.carrier}
          onChange={(e: any) => {
            setAddCourierDetails({
              ...addCourierDetails,
              carrier: e.target.value,
            })
          }}
          isError={isErrorField.carrier}
        />

        <Input
          labelValue='Courier Code'
          type='text'
          isRequired={false}
          value={addCourierDetails?.carrierCode}
          onChange={(e: any) => {
            setAddCourierDetails({
              ...addCourierDetails,
              carrierCode: e.target.value,
            })
          }}
          isError={isErrorField.warehouseName}
        />

        <Input
          labelValue='Product Name'
          type='text'
          isRequired={true}
          value={addCourierDetails?.productName}
          onChange={(e: any) => {
            setAddCourierDetails({
              ...addCourierDetails,
              productName: e.target.value,
            })
          }}
          isError={isErrorField.productName}
        />

        <Input
          labelValue='Product Code'
          type='text'
          isRequired={true}
          value={addCourierDetails?.productCode}
          onChange={(e: any) => {
            setAddCourierDetails({
              ...addCourierDetails,
              productCode: e.target.value,
            })
          }}
        />

        <Input
          labelValue='PartnerCode'
          type='text'
          value={addCourierDetails?.partnerCode}
          onChange={(e: any) => {
            setAddCourierDetails({
              ...addCourierDetails,
              partnerCode: e.target.value,
            })
          }}
          isError={isErrorField?.email}
        />

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Status</span>
          <ToggleSwitch
            text={addCourierDetails?.status ? 'Active' : 'Inactive'}
            setValue={addCourierDetails?.isActive}
            onToggleClick={(e: any) => {
              setAddCourierDetails({
                ...addCourierDetails,
                isActive: e.target.checked,
              })
            }}
          />
        </div>
      </div>

      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isLoading={createCourierIsLoading}
          disabled={createCourierIsLoading}
          isFullWidth
          onClick={() => {
            handleAddClick()
          }}>
          Save
        </Button>

        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}

AddCourier.defaultProps = {
  className: undefined,
}

export default AddCourier
