import { Badge } from 'components/atoms/Badge'

export const columns = [
  {
    customWidth: 80,
    Header: () => '',
    accessor: 'action',
    isProductImage: true,
    Cell: () => true,
    // Cell: ({ row }) => (

    // ),
  },
  {
    customWidth: 94,
    Header: () => 'Courier',
    accessor: 'carrier',
    Cell: ({ row }) => <div>{row?.original?.carrier}</div>,
  },
  {
    customWidth: 240,
    Header: () => 'Courier Code',
    accessor: 'carrierCode',
    Cell: ({ row }) => <div>{row?.original?.carrierCode}</div>,
  },
  {
    customWidth: 230,
    Header: () => 'Product Name',
    accessor: 'productName',
    Cell: ({ row }) => <div>{row?.original?.productName}</div>,
  },
  {
    customWidth: 185,
    Header: () => 'Product Code',
    accessor: 'productCode',
    Cell: ({ row }) => <div>{row?.original?.productCode}</div>,
  },
  {
    customWidth: 125,
    Header: 'Partner Code',
    accessor: 'partnerCode',
    Cell: ({ row }) => <div>{row?.original?.partnerCode}</div>,
  },
  {
    id: 'isActive',
    customWidth: 100,
    Header: () => 'Status',
    accessor: 'isActive',
    Cell: ({ row }) => (
      <div>
        {row.original.isActive ? (
          <Badge appearance='success' labelValue='ACTIVE' />
        ) : (
          <Badge appearance='error' labelValue='INACTIVE' />
        )}
      </div>
    ),
  },
]

export default columns
