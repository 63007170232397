import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteCourierInterface {
  id?: string
}

export const useDeleteCourier = () => {
  const mutate = useMutation(({ id }: DeleteCourierInterface) => {
    return deleteAPI(`/CarrierProduct/Delete/${id}`)
  })

  return mutate
}

export default useDeleteCourier
