import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetCouriersListInterface {
  take?: number
  skip?: number
  name?: string
}

export const useGetCouriersList = () => {
  const mutate = useMutation(
    ({ take, skip, name }: GetCouriersListInterface) => {
      return get(
        `/ListAllCarrierProducts?take=${take}&skip=${skip}&Name=${name}`,
      )
    },
  )

  return mutate
}

export default useGetCouriersList
