import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  setTableVariables,
  tableVariables,
  totalRowCount,
  setUpdateCourierDrawerIsOpen,
  setSelectedRowByButtonAction,
  getCourierListIsLoading,
  setDeleteCourierPopOutIsOpen,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    itemData.length > 0 ? setData([...itemData]) : setData([])
  }, [itemData])

  const { userDetails } = useSelector((state) => state.user)
  const editCourierAccess = userDetails?.userPermissions?.includes(
    'WarehouseEditAccess',
  )
  const modifiedColumns = !editCourierAccess
    ? columns.filter((column) => column.accessor !== 'action')
    : columns

  return (
    <div className='data-table w-full text-sm h-full' {...restProps}>
      <Table
        data={data}
        loading={loading}
        columns={modifiedColumns}
        setTableVariables={setTableVariables}
        tableVariables={tableVariables}
        totalRowCount={totalRowCount}
        setUpdateCourierDrawerIsOpen={setUpdateCourierDrawerIsOpen}
        setSelectedRowByButtonAction={setSelectedRowByButtonAction}
        getCourierListIsLoading={getCourierListIsLoading}
        setDeleteCourierPopOutIsOpen={setDeleteCourierPopOutIsOpen}
      />
    </div>
  )
}

export default DataTable
