import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getCourierByIdInterface {
  id?: string
}

export const useGetCourierById = () => {
  const mutate = useMutation(({ id }: getCourierByIdInterface) => {
    return get(`/CarrierProduct/${id}`)
  })

  return mutate
}

export default useGetCourierById
