import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import { useGetCourierById, useUpdateCourier } from 'framework/api/methods'

import { JsonLoader } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { Input } from 'components/atoms/Input'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'

export interface UpdateCourierProps {
  className?: string | undefined
  [x: string]: any
}

export const UpdateCourier: FC<UpdateCourierProps> = ({
  className,
  getWarehousesList,
  isActive,
  notify,
  onCloseClick,
  selectedRowByButtonAction,
}: UpdateCourierProps) => {
  const AddCourierClasses = CN(
    'UpdateCourier flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )
  const [updateCourierDetails, setUpdateCourierDetails] = useState<any>({
    id: '',
    carrier: '',
    productName: '',
    productCode: '',
    carrierCode: '',
    carrierName: '',
    partnerCode: '',
    isActive: false,
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    carrier: false,
    productName: false,
    productCode: false,
    carrierCode: false,
    carrierName: false,
    partnerCode: false,
    isActive: false,
  })

  /** API Call using react-query */
  const { mutate: getCourierByIdMutate, isLoading: getCourierByIdIsLoading } =
    useGetCourierById()

  /** Process the get warehouse Table Data */
  async function getCourierById(id: any): Promise<void> {
    getCourierByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          const {
            id,
            isActive,
            carrier,
            carrierCode,
            carrierName,
            partnerCode,
            productCode,
            productName,
          } = successData

          setUpdateCourierDetails({
            ...updateCourierDetails,
            id: id,
            carrier: carrier,
            isActive: isActive,
            carrierCode: carrierCode,
            carrierName: carrierName,
            partnerCode: partnerCode,
            productCode: productCode,
            productName: productName,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          onCloseClick()
        },
      },
    )
  }

  const { mutate: updateCourierMutate, isLoading: updateCourierIsLoading } =
    useUpdateCourier()

  /** Process the Update warehouse data */
  async function updateCourier() {
    updateCourierMutate(updateCourierDetails, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData.message, 'success')
        onCloseClick()
        getWarehousesList()
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  useEffect(() => {
    getCourierById(selectedRowByButtonAction.id)
  }, [selectedRowByButtonAction])

  const validateInputs = () => {
    const { carrier, productName, productCode, carrierName } =
      updateCourierDetails

    setIsErrorField({
      ...isErrorField,
      carrier: carrier ? false : true,
      productName: productName ? false : true,
      productCode: productCode ? false : true,
    })

    const isAllValidFields = carrier && productName && productCode
    return isAllValidFields
  }

  const handleUpdateClick = () => {
    const valid = validateInputs()
    if (valid) {
      updateCourier()
    }
  }

  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={400}
      title='Update Courier'>
      <div className={AddCourierClasses}>
        {getCourierByIdIsLoading && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
            <JsonLoader size='xs' />
          </div>
        )}

        <Input
          labelValue='Courier'
          type='text'
          isRequired={true}
          value={updateCourierDetails.carrier}
          onChange={(e: any) => {
            setUpdateCourierDetails({
              ...updateCourierDetails,
              carrier: e.target.value,
            })
          }}
          isError={isErrorField.carrier}
        />

        <Input
          labelValue='Courier Code'
          type='text'
          isRequired={false}
          value={updateCourierDetails.carrierCode}
          onChange={(e: any) => {
            setUpdateCourierDetails({
              ...updateCourierDetails,
              carrierCode: e.target.value,
            })
          }}
          isError={isErrorField.courierCode}
        />

        <Input
          labelValue='Product Name'
          type='text'
          isRequired={true}
          value={updateCourierDetails?.productName}
          onChange={(e: any) => {
            setUpdateCourierDetails({
              ...updateCourierDetails,
              productName: e.target.value,
            })
          }}
          isError={isErrorField.productName}
        />

        <Input
          labelValue='Product Code'
          type='text'
          value={updateCourierDetails?.productCode}
          onChange={(e: any) => {
            setUpdateCourierDetails({
              ...updateCourierDetails,
              productCode: e.target.value,
            })
          }}
          isError={isErrorField.productCode}
        />

        <Input
          labelValue='PartnerCode'
          type='text'
          value={updateCourierDetails?.partnerCode}
          onChange={(e: any) => {
            setUpdateCourierDetails({
              ...updateCourierDetails,
              partnerCode: e.target.value,
            })
          }}
        />

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Status</span>
          <ToggleSwitch
            text={updateCourierDetails?.isActive ? 'Active' : 'Inactive'}
            setValue={updateCourierDetails?.isActive}
            onToggleClick={(e: any) => {
              setUpdateCourierDetails({
                ...updateCourierDetails,
                isActive: e.target.checked,
              })
            }}
          />
        </div>
      </div>

      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isLoading={updateCourierIsLoading}
          disabled={updateCourierIsLoading}
          isFullWidth
          onClick={() => {
            handleUpdateClick()
          }}>
          Update
        </Button>

        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}

UpdateCourier.defaultProps = {
  className: undefined,
}

export default UpdateCourier
