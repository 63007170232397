import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateCourierInterface {
  id?: string
  carrier?: any
  productName?: string
  productCode?: string
  carrierCode?: string
  carrierName?: string
  partnerCode?: string
  isActive?: boolean
}

export const useUpdateCourier = () => {
  const mutate = useMutation(
    ({
      id,
      carrier,
      productName,
      productCode,
      carrierCode,
      carrierName,
      partnerCode,
      isActive,
    }: UpdateCourierInterface) => {
      return putAPI(`/CarrierProduct/${id}/Update`, {
        id: id,
        carrier: carrier,
        productName: productName,
        productCode: productCode,
        carrierCode: carrierCode,
        carrierName: carrierName,
        partnerCode: partnerCode,
        isActive: isActive,
      })
    },
  )

  return mutate
}

export default useUpdateCourier
