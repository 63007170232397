import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateCourierInterface {
  carrier?: any
  productName?: string
  productCode?: string
  carrierCode?: string
  carrierName?: string
  partnerCode?: string
}

export const useCreateCourier = () => {
  const mutate = useMutation(
    ({
      carrier,
      productName,
      productCode,
      carrierCode,
      carrierName,
      partnerCode,
    }: CreateCourierInterface) => {
      return postAPI('/CarrierProduct', {
        carrierProduct: {
          carrier: carrier,
          productName: productName,
          productCode: productCode,
          carrierCode: carrierCode,
          carrierName: carrierName,
          partnerCode: partnerCode,
        },
      })
    },
  )

  return mutate
}

export default useCreateCourier
