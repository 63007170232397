import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import { useDeleteCourier, useGetCouriersList } from 'framework/api/methods'

import { Button, PopUpAlert } from 'components/atoms'
import { AddCourier, UpdateCourier } from 'components/DrawerModals/Courier'

import { DataTable } from './CourierDataTable'

import 'react-toastify/dist/ReactToastify.css'

export interface CouriersProps {
  [x: string]: any
}

export const Couriers: FC<CouriersProps> = ({ className }: CouriersProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const editCourierAccess = userDetails?.userPermissions?.includes(
    'WarehouseEditAccess',
  )
  const [searchCourierName, setSearchCourierName] = useState('')
  const [rows, setRows] = useState({})
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [deleteCourierPopOutIsOpen, setDeleteCourierPopOutIsOpen] =
    useState(false)
  const [addCourierDrawerIsOpen, setAddCourierDrawerIsOpen] = useState(false)
  const [updateCourierDrawerIsOpen, setUpdateCourierDrawerIsOpen] =
    useState(false)
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    name: '',
  })
  const [selectedRowByButtonAction, setSelectedRowByButtonAction] = useState({
    id: '',
  })

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** API Call for get warehouses list using react-query */
  const { mutate: getCouriersListMutate, isLoading: getCourierListIsLoading } =
    useGetCouriersList()

  /** Process the get warehouses table data */
  async function getCouriersList() {
    getCouriersListMutate(tableVariables, {
      onSuccess: ({ data: successData }: any) => {
        setRows(successData.carrierProductsList)
        setTotalRowCount(successData?.totalCount)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API for Delete Warehouse */
  const { mutate: deleteCourierMutate } = useDeleteCourier()

  /** Process the Delete Warehouse */
  async function deleteCourier(id: string) {
    deleteCourierMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          setDeleteCourierPopOutIsOpen(false)
          notify(successData.message, 'success')
          getCouriersList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          setDeleteCourierPopOutIsOpen(false)
        },
      },
    )
  }

  /** call warehouses list when table variables change */
  useEffect(() => {
    getCouriersList()
  }, [tableVariables])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        name: searchCourierName,
      })
    }
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>Couriers List</h2>
          <div className='flex w-full items-center justify-between pt-6'>
            <div className='w-1/2'>
              {editCourierAccess && (
                <Button
                  onClick={() => {
                    setAddCourierDrawerIsOpen(true)
                  }}>
                  <i className='ri-add-circle-line pr-2'></i>Add New Courier
                </Button>
              )}
            </div>
            <div className='flex flex-col w-[432px] pl-2 relative'>
              <input
                onChange={(e: any) => {
                  setSearchCourierName(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  handleSearchKeyPress(e)
                }}
                type='text'
                placeholder='Search by Courier'
                className='pr-4 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm mt-2'
              />
              <i
                className='ri-search-2-line absolute top-[15px] left-[22px] text-1xl text-N-500 cursor-pointer'
                role='button'
                tabIndex={0}
                onClick={() => {
                  setTableVariables({
                    ...tableVariables,
                    name: searchCourierName,
                  })
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setTableVariables({
                      ...tableVariables,
                      name: searchCourierName,
                    })
                  }
                }}></i>
            </div>
          </div>
          <div className='w-full h-[calc(100%-100px)] pt-4'>
            <DataTable
              itemData={rows}
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setUpdateCourierDrawerIsOpen={setUpdateCourierDrawerIsOpen}
              setSelectedRowByButtonAction={setSelectedRowByButtonAction}
              getCourierListIsLoading={getCourierListIsLoading}
              setDeleteCourierPopOutIsOpen={setDeleteCourierPopOutIsOpen}
            />
          </div>

          {editCourierAccess && addCourierDrawerIsOpen && (
            <AddCourier
              isActive={addCourierDrawerIsOpen}
              onCloseClick={() => setAddCourierDrawerIsOpen(false)}
              notify={notify}
              getCouriersList={getCouriersList}
            />
          )}

          {editCourierAccess && updateCourierDrawerIsOpen && (
            <UpdateCourier
              isActive={editCourierAccess && updateCourierDrawerIsOpen}
              onCloseClick={() => setUpdateCourierDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              getWarehousesList={getCouriersList}
              notify={notify}
            />
          )}

          {editCourierAccess && deleteCourierPopOutIsOpen && (
            <PopUpAlert
              isActive={editCourierAccess && deleteCourierPopOutIsOpen}
              title='Delete Courier?'
              message='Are you sure you want to proceed? This is not reversible.'
              cancelButtonText='Cancel'
              confirmButtonText='Yes, I’m Sure'
              onCancelClick={() => setDeleteCourierPopOutIsOpen(false)}
              onOverlayClick={() => setDeleteCourierPopOutIsOpen(false)}
              onConfirmClick={() => {
                deleteCourier(selectedRowByButtonAction.id)
              }}
            />
          )}

          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}

Couriers.defaultProps = {}

export default Couriers
